import React, { FunctionComponent } from 'react';
import Section from "../components/Section";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import useTranslations from "../components/useTranslations";
import LocalizedLink from '../components/LocalizedLink';

type SroSignpostProps = {
};

const SroSignpost: FunctionComponent<SroSignpostProps> = (props) => {
    const { pages: { signpost } } = useTranslations()

    return (
        <>
            <Section className='py-8' fullWidth>
                <p className={'font-caveat text-xl mb-4 font-bold text-ink-40 text-center'}>{signpost.subTitle}</p>
                <Hero
                    outerContainer
                    headline={signpost.title}
                    subheadline={signpost.description}
                />
            </Section>
            <Section className={'py-20'}>
                {signpost.items.map(item => {
                    return (
                        <div className={'mb-14'} key={`signpost-${item.title}`} >
                            <Heading type="h3" className={'font-semibold mb-7'}>{item.title}</Heading>
                            <div className={'grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-10'}>
                                {item.links.map((link, index) => {
                                    return (
                                        <div key={`signpost-${link.link}-${index}`} >
                                            <LocalizedLink to={link.link}>
                                                <Heading type="h4" className={'underline link'}>{link.title}</Heading>
                                            </LocalizedLink>
                                            <p className={'text-lg'}>{link.description}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </Section>
        </>
    );
};

export default SroSignpost;
